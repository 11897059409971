.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.home-hero {
  width: 100%;
  display: flex;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}

.home-container01 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1320px;
  min-height: 85vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-card {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, .15), 0 8px 9px -5px hsla(0, 0%, 8%, .06) !important;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius1);
  flex-direction: column;
  backdrop-filter: saturate(200%) blur(30px);
  background-color: hsla(0, 0%, 100%, .8);
}

.home-text {
  text-align: center;
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-text01 {
  color: var(--dl-color-secondary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-unitandahalfunit);
}

.home-text02 {
  color: var(--dl-color-secondary-600);
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-tripleunit);
}

.home-contaier {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}

.home-container02 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-container03 {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius75);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
}

.home-icon {
  fill: var(--dl-color-gray-800);
  width: 24px;
  height: 24px;
}

.home-text62 {
  margin-top: var(--dl-space-space-unitandahalfunit);
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-container05 {
  display: flex;
  position: relative;
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, .15), 0 8px 9px -5px hsla(0, 0%, 8%, .06) !important;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sixunits);
  border-radius: var(--dl-radius-radius-radius1);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-size: cover;
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1677285192835-29a9d67ef5c1?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDQxfHxjb3R0b24lMjUyMGZpYmVyfGVufDB8fHx8MTY5ODIxMzI4N3ww&ixlib=rb-4.0.3&w=1400");
  background-position: center;
}

.home-container05:hover {
  transform: scale(1.04);
}

.home-container06 {
  height: 312px;
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text64 {
  color: var(--dl-color-gray-white);
  height: 139px;
  max-width: 200px;
  text-align: center;
}

.home-container07 {
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}

.home-divider {
  flex: 0 0 auto;
  width: 90%;
  height: 1px;
  display: flex;
  opacity: 0.25;
  margin-top: 4rem;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: 4rem;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}

.home-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-container09 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}

.home-container10 {
  display: flex;
  position: relative;
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, .15), 0 8px 9px -5px hsla(0, 0%, 8%, .06) !important;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sixunits);
  border-radius: var(--dl-radius-radius-radius1);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-size: cover;
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1600551575845-a6e27fc40f0f?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE4fHxjb3R0b24lMjUyMHNlZWRzfGVufDB8fHx8MTY5ODIxMzQ4OHww&ixlib=rb-4.0.3&w=1400");
  background-position: center;
}

.home-container10:hover {
  transform: scale(1.04);
}

.home-container11 {
  height: 312px;
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text65 {
  color: var(--dl-color-gray-white);
  max-width: 200px;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}

.home-image {
  top: 0px;
  left: auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 80vh;
  position: absolute;
  object-fit: cover;
  object-position: right;
  border-bottom-left-radius: 10rem;
}

.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  /* background-color: #ccc; */
}

.home-container13 {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}

.home-text68 {
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-text71 {
  color: #252525;
  max-width: 900px;
  text-align: center;
  font-weight: 400;
  margin-bottom: var(--dl-space-space-halfunit);
  /* background-color: rgb(255, 255, 255); */
}

.home-container14 {
  width: 100%;
  height: 800px;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unitandahalfunit);
  margin-right: var(--dl-space-space-unitandahalfunit);
  flex-direction: row;
}

.home-container15 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}

.home-image1 {
  width: 100%;
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, .15), 0 8px 9px -5px hsla(0, 0%, 8%, .06) !important;
  object-fit: cover;
  margin-left: 3rem;
  margin-right: -3rem;
  border-radius: var(--dl-radius-radius-radius75);
}

.home-text72 {
  flex: 0 0 auto;
  color: #cb0c9f;
  width: 40%;
  display: flex;
  padding: var(--dl-space-space-unit);
  font-size: 0.875rem;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unitandahalfunit);
  align-items: flex-start;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 1.5;
  border-color: #cb0c9f;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.25rem;
  margin-bottom: 8.5rem;
  flex-direction: column;
  text-transform: none;
  text-decoration: none;
}

.home-container16 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: var(--dl-space-space-triplequarterunit);
  padding-right: var(--dl-space-space-triplequarterunit);
  flex-direction: column;
}

.home-image2 {
  width: 90%;
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, .15), 0 8px 9px -5px hsla(0, 0%, 8%, .06) !important;
  margin-top: 8rem;
  object-fit: cover;
  margin-left: -1.5rem;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: 7.6rem;
}

.home-container17 {
  width: 90%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #252525;
}

.home-testimonials {
  width: 100%;
  height: 740px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 190px;
  padding-bottom: 30px;
  flex-direction: column;
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
}

.home-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  max-width: 1320px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  justify-content: space-between;
}

.home-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}

.home-container20 {
  flex: 1;
  max-width: 100%;
  height: 525px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-container21 {
  width: 100%;
  display: flex;
  max-width: auto;
  align-items: flex-start;
  flex-direction: column;
}

.home-text79 {
  color: var(--dl-color-gray-white);
  height: 63px;
}

.home-text80 {
  color: var(--dl-color-gray-white);
  height: 81px;
  opacity: 0.8;
}

.home-text83 {
  color: var(--dl-color-gray-white);
  opacity: 0.8;
  margin-bottom: var(--dl-space-space-doubleunit);
}

.home-container22 {
  width: auto;
  height: auto;
  display: flex;
  max-width: 100%;
  max-height: 50%;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
}

.home-logos {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.home-image3 {
  top: auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: auto;
  height: 599px;
  position: absolute;
  object-fit: cover;
}

.home-top-wave-image {
  top: -5px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}

@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }

  .home-container01 {
    max-width: 960px;
  }

  .home-card {
    width: 100%;
  }

  .home-contaier {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }

  .home-container07 {
    width: 45%;
    margin-left: var(--dl-space-space-unit);
  }

  .home-container09 {
    width: 45%;
    margin-right: var(--dl-space-space-unit);
  }

  .home-container12 {
    max-width: 960px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }

  .home-container13 {
    max-width: 80%;
  }

  .home-text71 {
    text-align: center;
  }

  .home-text72 {
    width: 50%;
  }

  .home-container18 {
    width: 100%;
    height: auto;
    padding-top: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    padding-bottom: 20px;
    justify-content: center;
  }

  .home-container20 {
    width: 100%;
    align-items: center;
    height: auto;
  }

  .home-text80 {
    color: var(--dl-color-gray-white);
  }

  .home-container22 {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px 0px;
    height: auto !important;
  }

  .home-container22>.feature-card-container {
    width: 45%;
  }

  .home-testimonials {
    height: auto;
  }

  .home-logos {
    display: none;
  }

  .home-container08 {
    margin-top: 0px;
  }
}

@media(max-width: 767px) {
  .home-card {
    align-items: center;
  }

  .home-container01 {
    max-width: 960px;
    min-height: auto;
    top: 100px;
    margin-bottom: 100px;
  }

  .home-testimonials {
    padding-top: 20px;
  }

  .home-text02 {
    text-align: center;
    margin-right: 0px;
    padding-right: 0px;
  }

  .home-container02 {
    width: 80%;
  }

  .home-text63 {
    text-align: center;
  }

  .home-container04 {
    flex-direction: column;
  }

  .home-container07 {
    width: 80%;
    margin-top: var(--dl-space-space-fourunits);
    align-items: center;
    margin-left: 0px;
  }

  .home-divider {
    margin-top: var(--dl-space-space-doubleunit);
    margin-bottom: var(--dl-space-space-doubleunit);
  }

  .home-container08 {
    margin-top: 16px;
    margin-bottom: 16px;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: auto;
  }

  .home-container08>.list-item-container {
    margin-top: 16px;
  }

  .home-container09 {
    width: 80%;
    align-items: center;
    margin-top: 64px;
    /* margin-bottom: var(--dl-space-space-fourunits); */
  }

  .home-image {
    display: none;
  }

  .home-container12 {
    max-width: 720px;
  }

  .home-container14 {
    height: auto;
    max-width: 720px;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
  }

  .home-container15 {
    width: 80%;
    padding-right: 0px;
  }

  .home-image1 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .home-text72 {
    width: 70%;
    align-self: center;
    margin-top: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-tripleunit);
  }

  .home-container16 {
    width: 80%;
  }

  .home-image2 {
    display: none;
  }

  .home-container18 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

@media(max-width: 479px) {
  .home-container22 {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px 0px;
    height: auto !important;
  }

  .home-testimonials {
    padding-top: 50px;
  }

  .home-container22>.feature-card-container {
    width: 100%;
  }

  .home-card {
    padding: var(--dl-space-space-unit);
  }

  .home-text {
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-text63 {
    text-align: center;
  }

  .home-container18 {
    padding-top: var(--dl-space-space-sixunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }

  .home-container14 {
    height: auto;
    max-width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
  }
}