.feature-card-container {
  flex: 0 0 auto;
  width: 20%;
  display: flex;
  margin-top: var(--dl-space-space-triplequarterunit);
  align-items: center;
  border-color: var(--dl-color-secondary-400);
  border-width: 1px;
  padding: var(--dl-space-space-triplequarterunit);
  border-radius: var(--dl-radius-radius-radius1);
  /* padding-right: var(--dl-space-space-triplequarterunit); */
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  box-shadow: 1px 1px 1px 1px var(--dl-color-gray-200);
  transition: all 0.3s ease-in-out;
  height: 100%;
}
.feature-card-image {
  height: 30px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unitandahalfunit);
}
.feature-card-text {
  color: var(--dl-color-gray-black);
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.feature-card-text1 {
  color: var(--dl-color-gray-500);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 767px) {
  .feature-card-container {
    width: 50%;
  }
}
@media(max-width: 479px) {
  .feature-card-container {
    width: 100%;
  }
}
